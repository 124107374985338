import { Box, Container, Typography } from "@mui/material";
import React from "react";
import ContactBg from "../Assets/contactBg2.jpg";

const Contact = () => {
  return (
    <Box sx={{ position: "relative", height: "300px", overflow: "hidden" }}>
      <Box
        sx={{
          backgroundImage: `url(${ContactBg})`,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          zIndex: 1,
        }}
      ></Box>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 2,
        }}
      ></Box>
      <Box
        sx={{
          position: "relative",
          color: "white",
          zIndex: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography variant="h5" component="div" sx={{ fontWeight: 200 }}>
            HAVE ANY QUESTIONS?
            <span> </span>
            <span component="div" style={{ fontWeight: 900 }}>
              CALL US{" "}
              <span style={{ color: "#c7f4fc", fontWeight: 900 }}>24/7</span>
            </span>
          </Typography>

          <Typography
            sx={{ fontSize: 50, fontWeight: "900", color: "#87CEEB" }}
          >
            +918074648069
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default Contact;
