import React from "react";
import About from "./layout/About";
import { Container } from "@mui/material";
import Menu from "./layout/Menu";
import Feedback from "./layout/Feedback";
import Footer from "./layout/Footer";
import Navbar from "./layout/Navbar";
import Location from "./layout/Location";
import Contact from "./layout/Contact";
import CopyRight from "./layout/CopyRight";
import HeaderSection from "./layout/HeaderSection";

const App = () => {
  return (
    <div>
      <Navbar />

      <Container>
        <div id="home">
          <HeaderSection />
        </div>
      </Container>
      <div id="contact">
        <Contact />
      </div>
      <Container>
        <div id="about">
          <About />
        </div>
        <div id="services">
          <Menu />
        </div>
      </Container>
      <div id="location">
        <Location />
      </div>
      <div id="feedback">
        <Feedback />
      </div>

      <div id="">
        <Footer />
      </div>
      <CopyRight />
    </div>
  );
};

export default App;
