
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5I8_mSg1G1D7yED-GToHqj0wD26ef8Jk",
  authDomain: "feedbackform-8c026.firebaseapp.com",
  projectId: "feedbackform-8c026",
  storageBucket: "feedbackform-8c026.appspot.com",
  messagingSenderId: "442699362647",
  appId: "1:442699362647:web:0452e9a533cf930fec4e12"
};

const app = initializeApp(firebaseConfig);
const feedbackDB = getFirestore(app)

export {feedbackDB};