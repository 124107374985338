import { Box, Typography } from "@mui/material";
import React from "react";
import MenuCards from "../Components/Cards/MenuCards";

const Menu = () => {
  return (
    <Box sx={{ mt: 6 }}>
      <Typography
        variant="h5"
        fontWeight="500"
        sx={{ m: 2, textAlign: "center", fontSize: 30, fontWeight: 700 }}
      >
        Our Service
      </Typography>

      <MenuCards />
    </Box>
  );
};

export default Menu;
