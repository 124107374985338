import { Box } from "@mui/material";
import React from "react";

const Location = () => {
  return (
    <Box sx={{ mt: 5, mb: 0 }}>
      <iframe
        title="Google Maps Location"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d243208.1035134726!2d83.2625912!3d17.7386722!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xa9e79e8cc2f58f35%3A0x7c191aabb2b8bf1f!2svizag%20services!5e0!3m2!1sen!2sin!4v1720353684336!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};

export default Location;
