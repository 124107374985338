import { Box, Typography } from "@mui/material";
import React from "react";
import AboutUsCard from "../Components/Cards/AboutUsCard";

const About = () => {
  return (
    <Box>
      <Box
        sx={{
          // mt: 2,
          p: 2,
          mb: 3,
          alignItems: "center",
          alignContent: "center",
          textAlign: "center",
        }}
      >
        <Typography variant="h5" fontWeight="700" sx={{ m: 2, fontSize: 30 }}>
          About
        </Typography>
        <Typography variant="body2" sx={{ color: "#595959" }}>
          We aim to deliver dependable, secure, and cutting-edge services for
          residential and commercial customers.We also offer services for
          repairing all types of household appliances such as Air conditioners,
          Refrigerators, Microwave ovens, Televisions, Washing machines. Our
          goal is to surpass expectations by providing customized solutions that
          meet your individual requirements and budget.
        </Typography>
      </Box>
      <Box>
        <AboutUsCard />
      </Box>
    </Box>
  );
};

export default About;
