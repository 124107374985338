import {
  Box,
  Card,
  CardContent,
  Grow,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import React from "react";
import MicrowaveImg from "../../Assets/microwave-oven-repair.webp";
import WashingmachineImg from "../../Assets/washingmachinerepair.jpeg";
import AcImg from "../../Assets/acmenu.jpg";
import RefrigeratorImg from "../../Assets/Refrigeratormenu.jpeg";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const MenuCards = () => {
  const menudata = [
    {
      id: 1,
      img: AcImg,
      title: "Ac Services",
      point1: "Deep cleaning of AC for fresh air",
      point2: "Installation & Uninstallation of AC",
      point3: "Gas Filling",
    },
    {
      id: 2,
      img: RefrigeratorImg,
      title: "Refrigerator Services",
      point1: "Defrost problem & Water Leakage",
      point2: "Ice Build Up in the Fridge",
      point3: "Your Refrigerator Is Not Cooling Enough",
    },
    {
      id: 3,
      img: WashingmachineImg,
      title: "Washing Machine Services",
      point1: "Motor repair or replacement",
      point2: "Belt repair or replacement",
      point3: "Your Refrigerator Is Not Cooling Enough",
    },
    {
      id: 4,
      img: MicrowaveImg,
      title: "MicroWave Oven Services",
      point1:
        "Microwave not heating, Turntable not rotating, Unusual noises, Door not closing or latching properly, Control panel issues, Sparks or burning smells, Faulty displays",
      point2:
        "Professional installation of new microwave ovens, ensuring proper setup and operation",
      point3:
        "Replacement of key components, such as magnetrons, diodes, fuses, and door switches.",
    },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(360px, 1fr))",
        gap: 2,
      }}
    >
      {menudata.map((data, index) => (
        <Grow in={true} timeout={{ enter: 500 + index * 500 }} key={data.id}>
          <Card
            sx={{
              borderImage: `linear-gradient(100deg, rgba(207, 206, 204, 0.4) 10%, rgba(255, 255, 255, 0.5) 50%) 1 fill`,
              "&:hover": {
                borderImage: `linear-gradient(200deg, rgba(207, 206, 204, 0.4) 10%, rgba(255, 255, 255, 0.5) 50%) 1 fill`,
                transition: "0.4s",
                transform: "translate(2px, -2px)",
                boxShadow: "-10px 10px 9px lightgray",
              },
            }}
          >
            <CardContent>
              <img
                src={data.img}
                alt={data.title}
                style={{ height: "100%", width: "100%", position: "cover" }}
              />
            </CardContent>
            <CardContent>
              <Typography variant="h6" fontWeight="600">
                {data.title}
              </Typography>
              <List>
                <ListItem sx={{ gap: 1 }}>
                  <ArrowCircleRightIcon />
                  {data.point1}
                </ListItem>
                <ListItem sx={{ gap: 1 }}>
                  <ArrowCircleRightIcon />
                  {data.point2}
                </ListItem>
                <ListItem sx={{ gap: 1 }}>
                  <ArrowCircleRightIcon />
                  {data.point3}
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grow>
      ))}
    </Box>
  );
};

export default MenuCards;
