import { Box, Link, Typography } from "@mui/material";
import React from "react";

const CopyRight = () => {
  return (
    <Box sx={{ backgroundColor: "#2b2b2b", pt: 5 }}>
      <Typography
        sx={{
          textDecoration: "none",
          color: "#595959",
          textAlign: "center",
        }}
      >
        © 2024{" "}
        <Link
          href="https://github.com/harsh17303/AcService.git"
          sx={{ textDecoration: "none" }}
        >
          Fail2Success
        </Link>
        , Inc. All rights reserved
      </Typography>
    </Box>
  );
};

export default CopyRight;
