import React, { useState, useEffect } from "react";
import { feedbackDB } from "../firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
import {
  Box,
  Button,
  Container,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import FeedbackCard from "../Components/Cards/FeedbackCard";

const Feedback = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [feedbackList, setFeedbackList] = useState([]);

  useEffect(() => {
    const fetchFeedback = async () => {
      const feedbackCollection = collection(feedbackDB, "feedbackData");
      const feedbackSnapshot = await getDocs(feedbackCollection);
      const feedbackData = feedbackSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeedbackList(feedbackData);
    };

    fetchFeedback();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent form from refreshing the page
    if (name && email && feedback && rating > 0) {
      const valRef = collection(feedbackDB, "feedbackData");
      await addDoc(valRef, {
        nameVal: name,
        emailVal: email,
        feedbackVal: feedback,
        ratingVal: rating,
      });

      // Clear the form fields and update the submitted state
      setName("");
      setEmail("");
      setFeedback("");
      setRating(0);
      setSubmitted(true);

      // Fetch the updated feedback list
      const feedbackCollection = collection(feedbackDB, "feedbackData");
      const feedbackSnapshot = await getDocs(feedbackCollection);
      const feedbackData = feedbackSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeedbackList(feedbackData);
    } else {
      alert("Please fill in all fields and provide a rating.");
    }
  };

  return (
    <Box sx={{ mt: 0, backgroundColor: "#ebebeb", pb: 5 }}>
      <Typography
        variant="h4"
        component="h1"
        align="center"
        sx={{ textAlign: "center", pt: 5, fontSize: 30, fontWeight: 700 }}
      >
        Feedback
      </Typography>
      {!submitted ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(360px, 1fr))",
          }}
        >
          <Container maxWidth="sm" sx={{ mb: 2 }}>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                mt: 4,
              }}
            >
              <Typography
                variant="h6"
                component="h1"
                align="center"
                gutterBottom
                sx={{ color: "#595959", fontWeight: 600 }}
              >
                Form
              </Typography>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ backgroundColor: "white" }}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{ backgroundColor: "white" }}
              />
              <TextField
                label="Feedback"
                variant="outlined"
                fullWidth
                required
                multiline
                rows={4}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                sx={{ backgroundColor: "white" }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography component="legend">Rating</Typography>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(e, newValue) => setRating(newValue)}
                />
              </Box>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                  backgroundColor: "#2b2b2b",
                  ":hover": {
                    color: "#2b2b2b",
                    outline: "1px solid #2b2b2b",
                    backgroundColor: "#fff",
                  },
                }}
              >
                Submit
              </Button>
            </Box>
          </Container>
          <Container sx={{ p: 2, height: "400px", overflowY: "auto" }}>
            <Box>
              <Typography
                variant="h6"
                component="h1"
                align="center"
                gutterBottom
                sx={{ textAlign: "center", color: "#595959", fontWeight: 600 }}
              >
                Over 100+ people trust us
              </Typography>
              {feedbackList.map((item) => (
                <FeedbackCard key={item.id} feedback={item} />
              ))}
            </Box>
          </Container>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" align="center" sx={{ mt: 4 }}>
            Submitted successfully
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ color: "#2b2b2b90" }}
          >
            refresh to see the Feedback.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Feedback;
