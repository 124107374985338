import React from "react";
import logo from "../Assets/logo.png";
import { Box, Link, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Footer = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
        gap: 1,
        justifyContent: "center",
        alignItems: "center",
        p: 5,
        pb: 5,
        // height: "400px",
        backgroundColor: "#2b2b2b",
        color: "white",
        textAlign: "center",
      }}
    >
      <Box sx={{ p: 2 }}>
        <img src={logo} alt="logo" style={{ height: "90px", width: "200px" }} />
      </Box>
      <Box sx={{ p: 1 }}>
        <Link
          href="https://maps.app.goo.gl/di648yNz182B8Vmk8"
          sx={{
            textDecoration: "none",
            color: "#fff",
          }}
        >
          <Typography variant="h5">
            {" "}
            <LocationOnIcon sx={{ color: "#fff" }} />
            Store Adress
          </Typography>
          <Typography sx={{ color: "#595959", ":hover": { color: "#8a8a8a" } }}>
            44-34-13, Nandagiri Nagar Rd, Akkayyapalem, Visakhapatnam, Andhra
            Pradesh 530016
            {/* Nandagiri Nagar, Akkayapalem Visakhapatnam, AP - 530016 */}
          </Typography>
        </Link>
      </Box>

      <Box sx={{ p: 2 }}>
        <Typography variant="h5">Contact Us</Typography>
        <Typography
          sx={{
            color: "#595959",
          }}
        >
          Satish | 8074648069 Mon - Sat | 8.00 AM to 8 PM
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
