import React from "react";
import { Box, Button, Typography } from "@mui/material";
import HeaderImg from "../Assets/homologo.png";

const HeaderSection = () => {
  return (
    <Box
      sx={{
        // border: "2px solid red",
        gap: 2,
        mt: 6,
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(320px, 1fr))",
      }}
    >
      <Box
        sx={{
          display: "grid",
          m: 2,
          p: 2,
          alignContent: "center",
        }}
      >
        <Typography variant="h6" sx={{ color: "#595959" }}>
          With over 10 years of experience
        </Typography>
        <Typography variant="h3" sx={{ color: "#87CEEB", fontWeight: 600 }}>
          HOME APPLIANCE
        </Typography>
        <Typography variant="h4" sx={{ color: "#A3AABE" }}>
          REPAIR SERVICES
        </Typography>
        <Typography variant="h6" sx={{ color: "#595959" }}>
          Offering Solution for all electrical issues
        </Typography>
        <Button
          variant="contained"
          href="tel: +91-807-464-8069"
          sx={{
            width: "150px",
            marginTop: 1,
            backgroundColor: "#2b2b2b",
            ":hover": {
              backgroundColor: "inherit",
              outline: "1px solid #2b2b2b",
              color: "#2b2b2b",
            },
          }}
        >
          Contact Us
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          src={HeaderImg}
          alt="Header"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",
          }}
        />
      </Box>
    </Box>
  );
};

export default HeaderSection;
