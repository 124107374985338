import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grow,
  Typography,
} from "@mui/material";
import React from "react";
import AcImg from "../../Assets/ac.png";
import RefrigratorImg from "../../Assets/refrigerator.png";
import WashinMachineImg from "../../Assets/washingmachine.png";
import microwaveImg from "../../Assets/microwave.png";

const AboutUsCard = () => {
  const Data = [
    {
      id: 1,
      image: AcImg,
      title: "AC Service",
      desc: "we specialize in providing top-notch AC repair services to keep your home or business cool and comfortable all year round. Our team of certified technicians is equipped with the skills and knowledge to diagnose and fix any issue with your air conditioning system quickly and efficiently.",
    },
    {
      id: 2,
      image: RefrigratorImg,
      title: "Refrigerator Services",
      desc: "we provide expert refrigerator repair services to ensure your appliance is running efficiently and keeping your food fresh. Our skilled technicians are equipped to handle repairs for all major brands and models, delivering prompt and reliable service.",
    },
    {
      id: 3,
      image: microwaveImg,
      title: "MicroWave Oven Services",
      desc: "we specialize in providing fast and reliable microwave oven repair services. Our team of certified technicians is skilled in diagnosing and fixing all types of microwave ovens, ensuring your appliance is back to working order quickly.",
    },
    {
      id: 4,
      image: WashinMachineImg,
      title: "Washing Machine Services",
      desc: " we offer reliable and efficient washing machine repair services to keep your laundry routine running smoothly. Our experienced technicians are well-versed in repairing all major brands and models of washing machines, ensuring that you receive the best possible service.",
    },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(360px, 1fr))",
        gap: 2,
      }}
    >
      {Data.map((data, index) => (
        <Grow in={true} timeout={{ enter: 500 + index * 500 }}>
          <Card
            sx={{
              // backgroundColor: "#e9eeff",
              borderImage: `linear-gradient(100deg, rgba(207, 206, 204, 0.4) 10%, rgba(255, 255, 255, 0.5) 50%) 1 fill`,
              "&:hover": {
                borderImage: `linear-gradient(200deg, rgba(207, 206, 204, 0.4) 10%, rgba(255, 255, 255, 0.5) 50%) 1 fill`,
                transition: "0.4s",
                transform: "translate(2px, -2px)",
                boxShadow: "-10px 10px 9px lightgray",
              },
            }}
          >
            <CardContent sx={{ p: 1 }}>
              <CardMedia
                sx={{
                  // border: "1px solid blue",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={data.image}
                  alt="card-img"
                  height="200"
                  width="auto"
                />
              </CardMedia>

              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ mt: 2, textAlign: "center" }}
              >
                {data.title}
              </Typography>

              <CardContent sx={{}}>
                <Typography
                  className="descripton"
                  sx={{ mt: 2, textAlign: "center" }}
                >
                  {data.desc}
                </Typography>
              </CardContent>
            </CardContent>
          </Card>
        </Grow>
      ))}
    </Box>
  );
};

export default AboutUsCard;
