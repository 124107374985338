import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Rating,
  Typography,
} from "@mui/material";

const FeedbackCard = ({ feedback }) => {
  return (
    <Box sx={{
              display: "grid",
              gap: 2,
              gridTemplateColumns: "repeat(auto-fit, minmax(240px, 1fr))",
            }}>
    <Card sx={{ mb: 2 }}>
      <CardContent>
      <Rating value={feedback.ratingVal} readOnly sx={{ mt: 1 }} />
      <Typography variant="body1" sx={{ mt: 1, ml:1, fontSize: 20 }}>
          {feedback.feedbackVal}
        </Typography>
        <Box
              sx={{
                display: "flex",
                mt: 1,
              }}
            >
        <Avatar />
        <Typography variant="h6" component="div" sx={{
                  ml: 2,
                  fontSize: 25,
                  fontWeight: 700,
                  alignContent: "center",
                }}>
          {feedback.nameVal}
        </Typography>
      </Box>
        
      </CardContent>
    </Card>
    </Box>
  );
};

export default FeedbackCard;
